class MatchupMaker {

  init() {
    this.matchupMakerControlsHolder = $('#matchupMakerControlsHolder');
    this.matchupMakerHolder = $('#matchupMakerHolder');
    this.matchupSelectFighter1 = $('#matchupSelectFighters--fighter1');
    this.matchupSelectFighter1Id = $('#matchupSelectFighters--fighter1Id');
    this.matchupSelectFighter2 = $('#matchupSelectFighters--fighter2');
    this.matchupSelectFighter2Id = $('#matchupSelectFighters--fighter2Id');
    this.matchupGoButton = $('#matchupGoButton');

    this.autocompleteFighters = this.matchupMakerControlsHolder.data('active-fighters-json');

    this.matchupSelectFighter1.autocomplete(
      { 
        source: this.autocompleteFighters,
        minLength: 2,
        select: function (event, ui) {
          matchup_maker.matchupSelectFighter1Id.val(ui['item']['data']);
        }
      } 
    );

    this.matchupSelectFighter2.autocomplete(
      { 
        source: this.autocompleteFighters,
        minLength: 2,
        select: function (event, ui) {
          matchup_maker.matchupSelectFighter2Id.val(ui['item']['data']);
        }
      } 
    );

    this.matchupGoButton.on('click', matchup_maker.matchupGoButtonClick);
  };

  matchupGoButtonClick() {
    let red_fighter_id = matchup_maker.matchupSelectFighter1Id.val();
    let blue_fighter_id = matchup_maker.matchupSelectFighter2Id.val();
    if (red_fighter_id && blue_fighter_id) {
      toastr.info('Loading Matchup...');
      matchup_maker.matchupMakerHolder.load('/fighters/ajax_matchup?red_fighter_id=' + red_fighter_id + '&blue_fighter_id=' + blue_fighter_id, {}, function(response, status, xhr) {
        if (status == 'error') {
          toastr.error('Unable To Load Matchup.');
        } else {
          toastr.success('Loaded.');
        }
      });
    } else {
      toastr.error('Please Pick 2 Fighters.');
    }
  };

}

export let matchup_maker = new MatchupMaker();