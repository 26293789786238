export class Responsiveness {

  init() {
    this.navMqString = `(max-width: 48rem)`;
    this.mobileNavToggle = document.querySelector('#menu-main--mobile-menu-toggle');
    this.menuMain = document.querySelector('#menu-main');

    // set up matchmedia
    this.navMq = window.matchMedia(this.navMqString);

    // set up event listener for matchmedia (different approach needed for different browsers)
    try {
      // Chrome & Firefox
      this.navMq.addEventListener('change', (e) => {
        responsiveness.checkBreakpoint(e);
      });
    } catch (e1) {
      try {
        // Safari
        this.navMq.addListener((e) => {
          responsiveness.checkBreakpoint(e);
        });
      } catch (e2) {
        console.error(e2);
      }
    }
    
    // check breakpoint initially
    if(this.navMq.matches) {
        this.siteMode = 'mobile';
        this.initMobile();
    } else {
        this.siteMode = 'desktop';
        this.initDesktop();
    }

    // setup mobile navigation
    this.mobileNavToggle.addEventListener('click',(e)=>{
      if(e.currentTarget.getAttribute('aria-expanded')=='true') {
        e.currentTarget.setAttribute('aria-expanded','false');
        this.menuMain.classList.add('hidden');
      } else {
        e.currentTarget.setAttribute('aria-expanded','true');
        this.menuMain.classList.remove('hidden');
      }
    })
  };

  checkBreakpoint(e) {
    // switch to mobile functionality
    if(e.matches) {
      this.initMobile();
    // switch to non-mobile functionality
    } else {
      this.initDesktop();
    }
  };

  initMobile() {
    console.log('initMobile');

    // check if we're moving from desktop, if so undo desktop-only stuff
    //if(this.siteMode == 'desktop') {}

    // add hidden class to main navigation initially
    this.menuMain.classList.add('hidden');

    // set site mode variable
    this.siteMode = 'mobile';
  };

  initDesktop() {
    console.log('initDesktop');
    // check if we're moving from mobile, if so then undo mobile-only things
    if(this.siteMode == 'mobile') {
      this.menuMain.classList.remove('hidden');
    }

    // set site mode variable
    this.siteMode = 'desktop';
  };
}

export let responsiveness = new Responsiveness();